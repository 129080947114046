<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { formatDate } from "date-fns";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import CloseIcon from "~/components/icons/close.svelte";
  import backendApi from "~/libs/backendApi";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /**
   * 日次レポートをダウンロードされたEC事業者のID
   * @type {number}
   */
  export let companyId;

  /**
   * 日次レポートをダウンロードした日時
   * @type {Date}
   */
  export let dateOfDownloadDailyReport;

  let open = false;
  let agreeUpdateStatusChecked = false;

  /**
   * 更新確認ダイアログを開く。
   */
  export function dialogOpen() {
    open = true;
  }

  /**
   * 更新確認ダイアログを閉じる。
   */
  function closeHandler() {
    agreeUpdateStatusChecked = false;
    open = false;
  }

  /**
   * 更新するボタンを押したときの処理
   */
  async function confirmHandler() {
    try {
      await execReportedAtApi();
      toast.info($_("message.updateComplete"));
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.updateUnauthorized.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
    closeHandler();
  }

  const execReportedAtApi = loadingProgress.wrapAsync(async () => {
    const body = {
      companyId: companyId,
      reportedAt: formatDate(dateOfDownloadDailyReport, "yyyy-MM-dd HH:mm:ss"),
    };

    await backendApi.registerDateReportedOfDailyReport(body);
  });
</script>

<div class="dialogWrapper">
  <Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 101;"
  >
    <Title id="cancel-dialog-title">
      <p>ダウンロード完了</p>
      <div class="closeButtonArea">
        <button class="closeButton" on:click={closeHandler}>
          <CloseIcon /></button
        >
      </div>
    </Title>
    <Content id="detail-dialog-content">
      <div class="noteArea">
        <p>
          CSVファイルのダウンロードが完了しました。<br />
          ファイル内の全てのデータを「EC事業者に報告済み」として更新して問題ないことを確認してください。
        </p>
        <FormField>
          <Checkbox bind:checked={agreeUpdateStatusChecked} />
          <span slot="label">問題がないことを確認した</span>
        </FormField>
      </div>
    </Content>
    <div class="buttonArea">
      <Button
        touch
        variant="unelevated"
        disabled={!agreeUpdateStatusChecked}
        on:click={confirmHandler}>更新する</Button
      >
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialogWrapper {
    :global(#cancel-dialog-title) {
      display: flex;
      padding-top: 20px;
    }
  }
  .closeButtonArea {
    margin: 0 0 0 auto;
    button {
      padding: 4px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 50%;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .noteArea {
    color: rgba(0, 0, 0, 0.7);

    :global(.mdc-form-field) {
      margin-top: 10px;
    }
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    margin: 0 16px 8px 0;
  }
</style>
