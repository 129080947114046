<script>
  import Button from "@smui/button";
  import { format as formatDate, parseISO } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { createEventDispatcher } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { addColumnFilters, addSortBy } from "svelte-headless-table/plugins";

  import { knowledgeResultData } from "~/libs/stores";
  import KnowledgeTrashIcon from "~/pages/Knowledge/KnowledgeTrashIcon.svelte";
  import { textIncludeFilter } from "~/pages/Search/filters";
  import TextFilter from "~/pages/Search/TextFilter.svelte";

  /**
   * @type {Array<import("~/libs/commonTypes").ShippingKnowledge>}
   */
  export let results;
  /** @type {Function} */
  export let deletedResultsReplace;

  (() => {
    knowledgeResultData.set(results);
  })();

  const dispatch = createEventDispatcher();

  const table = createTable(knowledgeResultData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "",
          id: "trashIcon",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(KnowledgeTrashIcon, {
              record: dataCell.value,
              deletedResultsReplace: deletedResultsReplace,
            }),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "",
          id: "updateButton",
          accessor: (item) => item,
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "住所",
          id: "address",
          accessor: (item) =>
            displayAdressAndType(item.address, item.type) ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "共有配達メモ",
          id: "memo",
          accessor: (item) => item.memo ?? "",
        }),
        table.column({
          header: "最終更新日時",
          id: "updatedAt",
          accessor: (item) =>
            formatDate(parseISO(item.updatedAt), "yyyy/MM/dd HH:mm", {
              locale: localeJa,
            }) ?? "",
        }),
        table.column({
          header: "最終更新者",
          id: "updatedBy",
          accessor: (item) => item.updatedBy ?? "",
        }),
      ];
      return columns;
    })(),
  );

  /**
   * 住所と種類をあわせて表示する。
   * @param {string} address 住所
   * @param {number} type 種類
   * @returns {string} 住所と種類
   */
  function displayAdressAndType(address, type) {
    let formattedAddressAndType;
    if (type === 0) {
      formattedAddressAndType = address;
    } else if (type === 1) {
      formattedAddressAndType =
        address + "<span style='color: #2b81d6;'>&nbsp;(近隣)</span>";
    }
    return formattedAddressAndType;
  }

  /**
   * 編集ボタンクリック時の処理
   * @param {object} cell
   */
  function handleUpdateButtonClick(cell) {
    const targetResult = cell.value;
    dispatch("dialogOpen", targetResult);
  }

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);

  $: resultsNum = $rows.length;
</script>

<div class="resultHeader">
  <h1 class="resultNumber">全 {resultsNum}件</h1>
</div>
<div class="mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table" {...$tableAttrs}>
      <thead {...$tableHeadAttrs}>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr class="mdc-data-table__header-row" {...rowAttrs}>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <th
                    class="mdc-data-table__header-cell"
                    {...attrs}
                    style={cell.id === "statusIcon" ||
                    cell.id === "detailButton"
                      ? ""
                      : "cursor: pointer;"}
                    on:click={props.sort.toggle}
                  >
                    <div class="th-item">
                      <Render of={cell.render()} />
                      {#if props.sort.order === "asc"}
                        <span class="material-icons">arrow_upward</span>
                      {:else if props.sort.order === "desc"}
                        <span class="material-icons">arrow_downward</span>
                      {/if}
                    </div>
                    {#if props.filter?.render}
                      <div class="filter-area">
                        <Render of={props.filter.render} />
                      </div>
                    {/if}
                  </th>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
        {#if $rows.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note">
              該当するデータがありません。
            </td>
          </tr>
        {:else}
          {#each $rows as row (row.id)}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
              <tr class="mdc-data-table__row" {...rowAttrs}>
                {#each row.cells as cell (cell.id)}
                  <Subscribe attrs={cell.attrs()} let:attrs>
                    {#if cell.id === "address"}
                      <td class="mdc-data-table__cell address-cell" {...attrs}>
                        {@html cell.render()}
                      </td>
                    {:else if cell.id === "memo"}
                      <td class="mdc-data-table__cell memo-cell" {...attrs}>
                        <Render of={cell.render()} />
                      </td>
                    {:else if cell.id === "updatedBy"}
                      <td
                        class="mdc-data-table__cell updated-user-cell"
                        {...attrs}
                      >
                        <Render of={cell.render()} />
                      </td>
                    {:else if cell.id === "updateButton"}
                      <td class="mdc-data-table__cell" {...attrs}>
                        <Button
                          style="height: 30px; width: 60px;"
                          touch
                          variant="unelevated"
                          on:click={handleUpdateButtonClick(cell)}>編集</Button
                        >
                      </td>
                    {:else}
                      <td class="mdc-data-table__cell" {...attrs}>
                        <Render of={cell.render()} />
                      </td>
                    {/if}
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: 96%;
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }

  .titleArea {
    height: 40px;
    display: flex;
  }

  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }

  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }

  .captionArea {
    background-color: #064491cb;
    width: 200px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    margin-top: 16px;
    color: #fff;
    font-weight: 900;
  }

  .resultArea {
    width: 96%;
    min-width: 822px;
    height: fit-content;
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }

  #progressBar {
    margin: 10px;
  }

  #noDataComment {
    margin: 10px;
    font-size: smaller;
  }

  .resultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .resultNumber {
    margin: 12px 20px 5px;
  }

  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 236px);
    overflow-x: auto;
    overflow-y: none;
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .memo-cell {
    min-width: 420px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  .address-cell {
    min-width: 340px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  .updated-user-cell {
    min-width: 200px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
</style>
